import React, { useState, useMemo } from 'react'
import { Flex, Grid, Text, Select, GridItem } from '@chakra-ui/react'

import Seo from '~components/shared/seo'
import Heading from '~components/shared/heading'
import ContentBox from '~components/shared/content-box'
import ContentBoxTitle from '~components/shared/content-box-title'
import OrdersChart from '~components/pages/dashboard/orders-chart'
import OrdersCount from '~components/pages/dashboard/orders-count'
import TopEventsBox from '~components/pages/dashboard/top-events-box'
import NearbyEventsBox from '~components/pages/dashboard/nearby-events-box'
import {
  FilterRange,
  getDatesRange,
  getRangeDisplay,
} from '~components/pages/dashboard/helpers'
import { useTicketOrdersByDateQuery } from '~graphql/generated'
import '../styles/index.css'
const IndexPage = () => {
  const [range, setRange] = useState<FilterRange>(FilterRange.THIS_MONTH)
  const [startDate, endDate] = useMemo(() => getDatesRange(range), [range])

  const { data, loading } = useTicketOrdersByDateQuery({
    variables: {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    },
    fetchPolicy: 'network-only',
  })

  const topEvents = React.useMemo(() => {
    if (data) {
      const eventIds = Array.from(
        new Set(
          data.ticketorders?.map(order => order?.ticket_sku?.festival?.id)
        )
      )

      const topEventsByOrders = eventIds
        .map(id => {
          const orders = data.ticketorders?.filter(
            order => order?.ticket_sku?.festival?.id === id
          )
          const event = orders?.[0]?.ticket_sku?.festival

          return {
            id: id || '',
            orders: orders?.length || 0,
            eventName: event?.name || '',
            eventSlug: event?.slug_name || '',
          }
        })
        .sort((a, b) => b.orders - a.orders)
        .slice(0, 10)

      return topEventsByOrders
    }

    return []
  }, [data])

  const totalOrders = data?.ticketorders || []
  const pendingOrders =
    data?.ticketorders?.filter(o => o?.fulfillment_status === 'pending') || []
  const fulfilledOrders =
    data?.ticketorders?.filter(o => o?.fulfillment_status === 'fulfilled') || []
  const canceledOrders =
    data?.ticketorders?.filter(o => o?.fulfillment_status === 'canceled') || []

  return (
    <>
      <Seo title="Home" />

      <Flex align="center" justify="space-between" mb={[6, 8]}>
        <Heading mb={0}>Dashboard</Heading>
        <Select
          w="150px"
          variant="filled"
          focusBorderColor="brand.300"
          value={range}
          onChange={e => setRange(e.target.value as FilterRange)}
        >
          {Object.values(FilterRange).map(r => (
            <option key={r} value={r}>
              {r}
            </option>
          ))}
        </Select>
      </Flex>

      <Grid
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(4, 1fr)']}
        gap={[6]}
      >
        <GridItem colSpan={[1, 2]} rowSpan={[1, 2]}>
          <ContentBox
            h="100%"
            shadow="xl"
            bgColor="gray.800"
            isLoading={loading}
          >
            <Flex mb={2} align="center" justify="space-between">
              <ContentBoxTitle mb={0} color="gray.400">
                Overview
              </ContentBoxTitle>
              <Text fontSize="sm" color="gray.400">
                {getRangeDisplay(startDate, endDate)}
              </Text>
            </Flex>
            <OrdersChart
              totalCount={totalOrders.length}
              pendingCount={pendingOrders.length}
              fulfilledCount={fulfilledOrders.length}
              canceledCount={canceledOrders.length}
            />
          </ContentBox>
        </GridItem>

        <GridItem>
          <ContentBox isLoading={loading}>
            <ContentBoxTitle>Total orders</ContentBoxTitle>
            <OrdersCount count={totalOrders.length} />
          </ContentBox>
        </GridItem>
        <GridItem>
          <ContentBox isLoading={loading}>
            <ContentBoxTitle>Pending orders</ContentBoxTitle>
            <OrdersCount count={pendingOrders.length} />
          </ContentBox>
        </GridItem>
        <GridItem>
          <ContentBox isLoading={loading}>
            <ContentBoxTitle>Fulfilled orders</ContentBoxTitle>
            <OrdersCount count={fulfilledOrders.length} />
          </ContentBox>
        </GridItem>
        <GridItem>
          <ContentBox isLoading={loading}>
            <ContentBoxTitle>Canceled orders</ContentBoxTitle>
            <OrdersCount count={canceledOrders.length} />
          </ContentBox>
        </GridItem>

        <GridItem colSpan={[1, 2, 4, 4, 2]}>
          <TopEventsBox isLoading={loading} topEvents={topEvents} />
        </GridItem>

        <GridItem colSpan={[1, 2, 4, 4, 2]}>
          <NearbyEventsBox />
        </GridItem>
      </Grid>
    </>
  )
}

export default IndexPage
